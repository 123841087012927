<template>
    <div>
        <!-- <div class="vx-row mb-12">
            <vs-button v-on:click="create()" icon="done" color="primary">Create Payment</vs-button>
        </div> -->
        <div class="vx-row mb-12">
            <div v-bind:class="[
              detail? detailShow + ' vertical-divider' : '',
              detailHide,
            ]">
                <data-table :responseData="responseData" :propsParams="params" :header="header" @reloadDataFromChild="reloadData">
                    <template slot="thead">
                        <!-- <th width="15%">
                  <vs-checkbox color="success" v-on:click="addAllValidate()" v-model="validateAll">Release All</vs-checkbox>
                </th> -->
                    </template>
                    <template slot="tbody">
                        <vs-tr :key="indextr" v-for="(tr, indextr) in responseData.records">
                            <vs-td class="whitespace-no-wrap">
                                <div class="mt-4 flex mr-1">
                                    <!-- <vx-tooltip text="Settlement" class="mr-4">
                                        <vs-button color="primary" type="line" icon-pack="feather" @click="onClickRequest(tr)" icon="icon-file-text" />
                                    </vx-tooltip> -->
                                    <vx-tooltip text="Show" class="mr-4">
                                        <vs-button color="green" type="line" icon-pack="feather" @click="edit(tr)" icon="icon-eye" />
                                    </vx-tooltip>
    
                                    <!-- <vx-tooltip text="Cancel" class="mr-4">
                                        <vs-button color="red" type="line" icon-pack="feather" @click="promptCancel(tr)" icon="icon-x-square" />
                                    </vx-tooltip> -->
                                </div>
                            </vs-td>
                            <vs-td>
                                {{ tr.shipment_number }}
                            </vs-td>
                            <vs-td>
                                {{ dateFormat(tr.delivery_plan_date) }}
                            </vs-td>
                            <vs-td>
                                {{ tr.count_do_pickup }}
                            </vs-td>
                            <vs-td>
                                {{ tr.driver_name }}
                            </vs-td>
                            <vs-td>
                                {{ tr.vehicle_name }}
                            </vs-td>
                            <vs-td>
                                {{ tr.shipment_cost_code }}
                            </vs-td>
                            <vs-td>
                                {{ ((tr.vehicle_type === '' || tr.vehicle_type === null || typeof tr.vehicle_type  === null )?'OnCall':(tr.vehicle_type.toLowerCase() == 'internal'?'Internal':'Oncall'))}}
                            </vs-td>
                        </vs-tr>
                    </template>
                </data-table>
            </div>
            <vs-prompt title="Confirmation" color="primary" @accept="confirmAdjustment" @cancel="closeDetail" @close="closeDetail" :buttons-hidden="false" :active.sync="confirmPrompt">
                <div class="con-exemple-prompt">
                    Shipment Cost Execution
                    <br />
                    Are you sure to confirm <b>{{this.selectedData.Code}}</b> Shipment COst Execution ?
                    <br />
                    <br />
                    <!-- <vs-textarea v-model="notes"/> -->
                </div>
            </vs-prompt>
            <vs-prompt title="Confirmation" color="primary" @accept="cancelAdjustment" @cancel="closeDetail" @close="closeDetail" :buttons-hidden="false" :active.sync="cancelPrompt">
                <div class="con-exemple-prompt">
                    Shipment Cost Execution
                    <br />
                    Are you sure to cancel <b>{{this.selectedData.Code}}</b> Shipment COst Execution ?
                    <br />
                    <br />
                    <!-- <vs-textarea v-model="notes"/> -->
                </div>
            </vs-prompt>
            <transition name="detail-fade">
                <div v-if="detail" v-bind:class="[
                    detail? ' nonfixed vx-col md:w-2/3 w-full mb-base' : '',
                    detailHide,
                ]">
                    <div>
                        <vs-col style="padding-bottom: 8px" vs-offset="8" vs-type="flex" vs-justify="rigth" vs-align="rigth" vs-w="4">
                            <vs-button size="small" v-on:click="closeDetail" color="grey" icon-pack="feather" icon="icon-x-square">Close</vs-button>
                        </vs-col>
                        <template v-if="show == 1">
                            <edit :selected="selectedData" @closeDetail="closeDetail" />
                        </template>
                        <template v-else>
                            <advanceRequest :selected="selectedData" @closeDetail="closeDetail" />
                        </template>
                    </div>
                </div>
            </transition>
        </div>
    </div>
    </template>
    
    <script>
    import create from "../form-create.vue";
    import edit from "./form.vue";
    import advanceRequest from "./form.vue";
    import moment from "moment";
    export default {
        props: {
            selected: Object,
            option: Object,
            shipmentDate: Object,
            filterDriver: Object,
            filterVehicle: Object,
            filterType: Object,
        },
        components: {
            create,
            edit,
            advanceRequest
        },
        data() {
            return {
                typePayment: ["", "Giro", "Cheque", "Transfer"],
                confirmPrompt: false,
                cancelPrompt: false,
                status: 3,
                show: 0,
                params: {
                    search: "",
                    length: 10,
                    page: 1,
                    order: "desc",
                    sort: "op.id",
                },
                header: [{
                        text: "Action",
                        sortable: false,
                    },
                    {
                        text: "Shipment Doc",
                        value: "shipment_number",
                        // width: '5%'
                    },
                    {
                        text: "Shipment Date",
                        value: "delivery_plan_date",
                    },
                    {
                        text: "Number of DO",
                        value: "count_do_pickup",
                    },
                    {
                        text: "Driver",
                        value: "driver_name",
                    },
                    {
                        text: "Vehicle",
                        value: "vehicle_name",
                    },
                    {
                        text: "Advance Cash Doc",
                        value: "shipment_number",
                    },
                    {
                        text: "Shipment Cost Type",
                        value: "type",
                    },
                ],
                responseData: {},
                detailShow: "vx-col md:w-1/3 w-full mb-base",
                detailHide: "core vx-col md:w-1/1 w-full mb-base",
                detail: 0,
                selectedData: {},
                driver_ids: [],
                vehicle_ids: [],
                type_name: [],
            };
        },
        computed: {},
        watch: {
            shipmentDate() {
                this.reloadData(this.params);
            },
            filterDriver() {
                this.reloadData(this.params);
            },
            filterVehicle() {
                this.reloadData(this.params);
            },
            filterType() {
                this.reloadData(this.params);
            },
        },
        mounted() {
            // this.reloadData(this.params);
    
            // this.getData();
            // this.page(1);
            // this.dataId = this.data[0].id
        },
        methods: {
            create() {
                this.selectedData = {
                    ID: 0
                }
                this.detail = 1;
            },
            edit(selected) {
                this.selectedData = selected
                console.log(selected)
                console.log(this.detail, "detailnya disini");
                this.detail = true;
                this.show = 1;
            },
            onClickRequest(selected) {
                this.selectedData = selected
                console.log(selected)
                console.log(this.detail, "detailnya disini");
                this.detail = true;
                this.show = 2;
            },
            promptConfirm(data) {
                this.confirmPrompt = true
                this.status = 3
                this.cancelPrompt = false
                this.selectedData = data
                console.log("confirm")
            },
            promptCancel(data) {
                this.confirmPrompt = false
                this.status = 6
                this.cancelPrompt = true
                this.selectedData = data
                console.log("confirm")
            },
            dateFormat(value) {
                return moment(String(value)).format("DD/MM/YYYY");
            },
            closeDetail(params = false) {
                console.log(params)
                window.scrollTo(0, 0);
                this.detail = false;
                console.log(this.detail, "detailnya disini");
                this.confirmPrompt = false
                this.show = 0
                this.status = 2
                this.cancelPrompt = false
                if (params == true || this.selectedData.ID != 0) {
                    this.reloadData(this.params);
                }
                this.selectedData = {
                    ID: 0,
                };
            },
            confirmAdjustment() {
                let params = {
                    id: this.selectedData.ID,
                    status: this.status
                }
                this.$vs.loading();
                this.$http.post('/api/v1/uang-jalan/change-status', params).then((result) => {
                    this.$vs.loading.close();
                    if (result.code == 200) {
                        this.closeDetail(true)
                        this.$vs.notify({
                            title: "Success",
                            text: "",
                            color: "success",
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-check",
                        });
                    } else {
                        this.closeDetail()
                        this.$vs.notify({
                            title: "Error",
                            text: result.message,
                            color: "danger",
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-check",
                        });
                    }
                }).catch((e) => {
                    console.log(e)
    
                })
            },
            cancelAdjustment() {
                let params = {
                    id: this.selectedData.ID,
                    status: this.status
                }
                this.$vs.loading();
                this.$http.post('/api/v1/uang-jalan/change-status', params).then((result) => {
                    this.$vs.loading.close();
                    if (result.code == 200) {
                        this.closeDetail()
                        this.$vs.notify({
                            title: "Success",
                            text: "",
                            color: "success",
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-check",
                        });
                    } else {
                        this.closeDetail()
                        this.$vs.notify({
                            title: "Error",
                            text: result.message,
                            color: "danger",
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-check",
                        });
                    }
                }).catch((e) => {
                    console.log(e)
    
                })
            },
            dataTable(params) {
                return new Promise((resolve, reject) => {
                    this.$http.get('/api/v1/uang-jalan/transport-plan/datatable', {
                        params: {
                            status: 10,
                            type: 4,
                            search: params.search,
                            length: params.length,
                            page: params.page,
                            order: params.order,
                            sort: params.sort,
                            start_shipment_date:this.shipmentDate.startDate ? moment(this.shipmentDate.startDate).format("YYYY-MM-DD") : null,
                            end_shipment_date:this.shipmentDate.endDate ? moment(this.shipmentDate.endDate).format("YYYY-MM-DD") : null,
                            driver_ids:this.driver_ids,
                            vehicle_ids:this.vehicle_ids,
                            type_names:this.type_names,
                        }
                    }).then((r) => {
                        resolve(r)
                    }).catch((e) => {
                        reject(e)
                    })
                })
            },
            reloadData(params) {
                this.driver_ids = this.filterDriver.map(x => x.id),
                this.vehicle_ids = this.filterVehicle.map(x => x.id),
                this.type_names =  this.filterType.map(x => x.name),
                this.params = params;
                console.log(params);
                this.$vs.loading();
                const dataTable = this.dataTable(params);
                dataTable.then((r) => {
                    console.log(r);
                    if (r.code == 500) {
                        this.$vs.loading.close();
                    } else if (r.code == 200) {
                        this.$vs.loading.close();
                        this.data = r.data.records;
                        this.responseData = r.data;
                        // this.responseData.length = r.data.otherPayment.length;
                        // this.checkedAll = false;
                    } else {
                        this.$vs.loading.close();
                    }
                });
    
            },
        },
    };
    </script>
     
    <style scoped>
    .nonfixed {
        position: inherit;
        padding-left: 20px;
    }
    
    .core-enter-active {
        transition: all 0.3s ease;
    }
    
    .core-leave-active {
        transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
    }
    
    .core-enter,
    .core-leave-to
    
    /* .slide-fade-leave-active below version 2.1.8 */
        {
        transform: translateX(10px);
        opacity: 0;
    }
    
    .core {
        -webkit-transform-origin-y: all 1s ease;
        -webkit-transition: all 1s ease;
        -moz-transition: all 1s ease;
        -o-transition: all 1s ease;
        transition: all 1s ease;
    }
    
    .vs-con-table .vs-con-tbody .vs-table--tbody-table {
        font-size: 12px;
    }
    
    .vertical-divider {
        border-right: 1px solid #7367f0;
        /* min-height: 800px; */
        /* height: 100%; */
        -webkit-mask-position-y: fixed;
        /* padding: 5px; */
    }
    
    .vs-con-table.stripe .tr-values:nth-child(2n) {
        background: beige;
    }
    
    .colored {
        border: 1px solid #7367f0;
        position: fixed;
        left: 4%;
        top: 40%;
        max-width: 45%;
        z-index: 999999999999;
        background: antiquewhite;
        background-color: antiquewhite;
    }
    </style>
    